import 'twin.macro';

import { navigate } from '@reach/router';
import bg_select from 'assets/svg/bg-select.svg';
import IconFile from 'assets/svg/icons/file.inline.svg';
import IconSelect from 'assets/svg/select-text.inline.svg';
import ContentFit from 'components/Atoms/ContentFit';
import Button from 'components/Molecules/Button';
import Comment from 'components/Organisms/Comment';
import { routes } from 'constants/routes';
import HeaderContext from 'providers/HeaderProvider';
import ThemeContext from 'providers/ThemeProvider';
import React, { useContext, useEffect } from 'react';
import { css } from 'twin.macro';

const bgSelect = css`
	max-height: 800px;
	height: 65vw;
	position: relative;
	background-image: url(${bg_select});
	background-position: center;
	background-repeat: no-repeat;
	background-size: contain;
`;

export default function welcomeLimitAdvancedForm() {
	const { setHeader } = useContext(HeaderContext);
	const { setPrimaryColor } = useContext(ThemeContext);

	useEffect(() => {
		setHeader({ title: 'Wniosek faktoringowy', isSelect: true });
		setPrimaryColor('#3B81B9');
	}, []);

	return (
		<div>
			<div css={bgSelect} className='flex flex-col items-center justify-center w-full -mt-55 -mb-07 xs:mt-0'>
				<IconSelect className='w-120 h-50 mt-30' />
				<p className='font-semibold -mt-07'>Faktoring zagraniczny</p>
			</div>

			<ContentFit siblingHeight='65vw' animated>
				<Comment secondary isSelect title='Przygotowanie wniosku zajmie Ci 2-5 minut' />

				<Button primary onClick={() => navigate(routes.SELECT_ADVANCED_FORM)} tw='bg-primary mx-auto mt-07 mb-15'>
					<IconFile/><p>Złóż wniosek</p>
				</Button>
			</ContentFit>
		</div>
	);
}
